.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;

  &.dark {
    background-color: #121212;
    color: #ffffff;

    .head-text,
    .bold-text,
    .p-text {
      color: #ffffff;
    }

    .app__profile-item {
      background-color: #1e1e1e;
    }
  }
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;
  transition: background-color 0.3s ease;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}

// Add these new styles for dark mode
.dark {
  .app__profile-item {
    background-color: #1e1e1e;
    color: #ffffff;
  }

  .head-text,
  .bold-text,
  .p-text {
    color: #ffffff;
  }
}
