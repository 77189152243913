.privacy-policy {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.privacy-policy h1 {
  text-align: center;
  margin-bottom: 20px;
}

.privacy-policy h2 {
  margin-top: 20px;
}

.privacy-policy p {
  margin-bottom: 10px;
  line-height: 1.6;
}
