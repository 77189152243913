table {
  width: 100%;
  transition: all 0.3s ease 0s;
}

h1 {
  text-align: center;
  font: 20px Arial;
}

td,
th {
  text-align: center;
  padding: 20px;
}

.button {
  width: 100px;
  height: 40px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.blue {
  background-color: #3498db;
  border: none;
  box-shadow: 0px 5px 0px 0px #07526e;
  transition: all 0.3s ease 0s;
  height: 20px;
  color: white;
  width: 50px;
}

.gray {
  background-color: #a1a1a1;
  border: 1px solid transparent;
  box-shadow: 0px 5px 0px 0px #5b5b5b;
  transition: all 0.3s ease 0s;
  height: 20px;
  width: 50px;
}

.green {
  background-color: #2ecc71;
  border: 1px solid #18693a;
  box-shadow: 0px 5px 0px 0px #18693a;
  transition: all 0.3s ease 0s;
}

.orange {
  background-color: #e67e22;
  border: 1px solid #d35400;
  box-shadow: 0px 5px 0px 0px #d35400;
  transition: all 0.3s ease 0s;
}

.grey {
  background-color: #a1a1a1;
  border: 1px solid #95a5a6;
  box-shadow: 0px 2px 10px 4px #454545;
  transition: all 0.3s ease 0s;
  height: 20px;
}

.one {
  border-radius: 3px;
  border: none;
  transition: all 0.3s ease 0s;
}

.two {
  border-radius: 3px;
  transition: all 0.3s ease 0s;
}

.three {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
  border: none;
  transition: all 0.3s ease 0s;
}

.four {
  box-shadow: none;
  border: none;
  transition: all 0.3s ease 0s;
}

.five {
  border: none;
  box-shadow: none;
  transition: all 0.3s ease 0s;
}

.one:active {
  box-shadow: 0px 2px 10px 2px #454545;
  transform: scale(0.9);
  transition: all 0.3s ease 0s;
}

.two:active {
  box-shadow: none;
  transform: translate(0px, 5px);
  transition: all 0.3s ease 0s;
}

.three:active {
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease 0s;
}

.four:hover {
  opacity: 0.8;
  transition: all 0.3s ease 0s;
}

.four:active {
  width: 95px;
  height: 35px;
  transition: all 0.3s ease 0s;
}

.blue:active {
  background-color: #2c81ba;
  transition: all 0.3s ease 0s;
}

.red:active {
  background-color: #a33025;
  transition: all 0.3s ease 0s;
}

.green:active {
  background-color: #229955;
  transition: all 0.3s ease 0s;
}

.orange:active {
  background-color: #d1731f;
  transition: all 0.3s ease 0s;
}

.grey:active {
  background-color: #808080;
  transition: all 0.3s ease 0s;
}

.bubble {
  color: white;
  background: #9b9b9b;
  border-radius: 10px;
  margin-left: 15px;
  cursor: default;
  border: transparent;
  transition: all 0.3s ease 0s;
}

.bubble:active {
  color: white;
  border-radius: 10px;
  background: #808080;
}

.bubble2 {
  color: white;
  border: transparent;
  transition: all 0.3s ease 0s;
  background: #288ff7;
  border-radius: 10px;
  float: right;
  cursor: default;
  margin-right: 15px;
}

.bubble2:active {
  background: #076fd6;
  color: white;
}

/* Specific chat styles */
.chat-app {
  position: relative;
  z-index: 1;
}

.chat-container {
  width: 360px;
  height: 600px;
  background-color: white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 1000;
}

.close-chat-btn {
  background: none;
  border: none;
  color: #007bff;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.close-chat-btn:hover {
  color: #0056b3;
}

.chat-box {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  margin-top: 40px; /* Adjust for close button */
}

.chat-message {
  display: flex;
  margin: 10px 0;
}

.chat-message.user {
  justify-content: flex-end;
}

.chat-message.assistant {
  justify-content: flex-start;
}

.message-content {
  max-width: 70%;
  padding: 10px 15px;
  border-radius: 20px;
  position: relative;
}

.user .message-content {
  background-color: #dcf8c6;
  color: #333;
  border-bottom-right-radius: 0;
}

.assistant .message-content {
  background-color: #ececec;
  color: #333;
  border-bottom-left-radius: 0;
}

.input-box {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.input-box textarea {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  resize: none;
  outline: none;
  font-size: 14px;
}

.input-box button {
  background-color: var(--secondary-color);
  border: none;
  color: white;
  padding: 10px;
  margin-left: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-box button:hover {
  background-color: #0056b3;
}

.open-chat-btn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: var(--secondary-color);
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.open-chat-btn:hover {
  background-color: #0056b3;
}

.no-scroll {
  overflow: hidden;
}

/* Media Queries for Responsiveness */

@media (max-width: 768px) {
  .chat-container {
    width: 90%;
    height: 80%;
    bottom: 60px;
    right: 5%;
    left: 5%;
    border-radius: 20px; /* Add some rounding for a more iPhone-like look */
  }

  .input-box textarea {
    font-size: 12px;
  }

  .input-box button {
    padding: 8px;
    font-size: 18px;
  }

  .open-chat-btn {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .chat-container {
    width: 95%;
    height: 80%;
    bottom: 10px;
    right: 2.5%;
    left: 2.5%;
    border-radius: 20px; /* Rounded corners for iPhone-like look */
  }

  .input-box textarea {
    font-size: 12px;
  }

  .input-box button {
    padding: 8px;
    font-size: 16px;
  }

  .open-chat-btn {
    padding: 8px 14px;
    font-size: 14px;
    bottom: 20px;
    left: 10px;
  }

  .close-chat-btn {
    font-size: 24px;
  }
}
